import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SEO from "../utils/SEO";
import ReactPlayer from "react-player";
// ====

const Video = (props) => {
  const heros = Array(
    "https://vimeo.com/386374263",
    "https://vimeo.com/386378308",
    "https://vimeo.com/386378863",
    "https://vimeo.com/433819304"
  );
  const herosTotal = heros.length - 1;

  const [herosCurrent, setHerosCurrent] = useState(0);

  const [herosCurrentUrl, setHerosCurrentUrl] = useState(heros[0]);

  const [videoProgress, setVideoProgress] = useState(false);

  const [loadingCover, setLoadingCover] = useState(true);

  const handleReady = () => {
    console.log("The video is ready");
    //    setLoadingCover(false);
  };

  const handleProgress = ({ played }) => {
    console.log(played);
    if (played > 0 && played < 0.95 && loadingCover === true) {
      console.log("Hide the cover");
      setLoadingCover(false);
    }

    if (played > 0.95 && loadingCover === false) {
      console.log("Show the cover to mask the video swap");
      setLoadingCover(true);
    }
  };

  const handleEnded = () => {
    console.log("The video has ended, load the next video");
    // Ensure the cover is over the video
    setLoadingCover(true);
    handleNext();
    // Update the heroCurrent
    //    setHerosCurrent(herosCurrent + 1);
  };

  const handleNext = () => {
    console.log("Load the next video");
    let heroNext = herosCurrent + 1;
    if (heroNext > herosTotal) {
      heroNext = 0;
    }

    // Update the URL of the video
    setHerosCurrentUrl(heros[heroNext]);
    // Also update the counter
    setHerosCurrent(heroNext);
  };

  return (
    <>
      <h1>
        Hero {herosCurrent + 1} of {herosTotal + 1}
      </h1>
      <ReactPlayer
        url={herosCurrentUrl}
        controls={true}
        onReady={handleReady}
        onProgress={handleProgress}
        onEnded={handleEnded}
        config={{
          vimeo: {
            playerOptions: { background: true },
          },
        }}
      />
      <p>{herosCurrentUrl}</p>
      <p
        style={{
          backgroundColor: `${loadingCover === true ? "#CC0000" : "#0000CC"}`,
        }}
      >
        {loadingCover === true ? "SHOW COVER" : "HIDE COVER"}
      </p>
    </>
  );
};

export default Video;

// ====

Video.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Video.defaultProps = {
  data: null,
};
